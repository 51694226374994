// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actualites-au-soudan-js": () => import("./../../../src/pages/actualites-au-soudan.js" /* webpackChunkName: "component---src-pages-actualites-au-soudan-js" */),
  "component---src-pages-auteurs-js": () => import("./../../../src/pages/auteurs.js" /* webpackChunkName: "component---src-pages-auteurs-js" */),
  "component---src-pages-culture-js": () => import("./../../../src/pages/culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-film-js": () => import("./../../../src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-histoire-js": () => import("./../../../src/pages/histoire.js" /* webpackChunkName: "component---src-pages-histoire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/NotFound.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-politique-js": () => import("./../../../src/pages/politique.js" /* webpackChunkName: "component---src-pages-politique-js" */),
  "component---src-templates-auteur-js": () => import("./../../../src/templates/Auteur.js" /* webpackChunkName: "component---src-templates-auteur-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/SingleArticle.js" /* webpackChunkName: "component---src-templates-single-article-js" */)
}

